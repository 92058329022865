import React, { Component } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Tabitem extends Component {
    render() {
        var { ticon, text, description, colClass } = this.props;
        const descriptionHtml = this.props['description-html'];
        let descrItem = <p>{description}</p>
        if (descriptionHtml) {
            descrItem = <p dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
        }
        return (
            <div className={`col-lg-5 col-md-6 ${colClass}`}>
                <div className="software_service_item mb_70">
                    {/* <FontAwesomeIcon icon={["fa-solid", ticon].join(' ')} /> */}

                    {/* <FontAwesomeIcon icon="fa-solid fa-arrows-spin" /> */}
                    {/* <FontAwesomeIcon icon={ticon} type="solid"/> */}
                    <i className={ticon}></i>

                    <h5 className="mt_30 mb_15">{text}</h5>
                    {descrItem}
                </div>
            </div>
        )
    }
}
export default Tabitem;