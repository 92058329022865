import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import i18n from 'i18next';

const LangRouteWrapper = () => {
  const {lang} = useParams();

  useEffect(() => {
    if (lang && lang !== i18n.language) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return <Outlet />;
};

export { LangRouteWrapper };
