import React from 'react';
import { useTranslation } from 'react-i18next';

const ForSchoolsText =()=>{
    const { t } = useTranslation();
    return(
        <section className="software_featured_area_two sec_pad">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="software_featured_img wow fadeInLeft" data-wow-delay="0.2s">
                            <img className="img-fluid" src={require("../../img/home5/featured_img1.png")} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-5 offset-lg-1 d-flex align-items-center pl-0">
                        <div className="software_featured_content">
                            <h2 className="f_700 f_size30 l_height_40 w_color f_p mb-30 wow fadeInRight" data-wow-delay="0.2s">{t("forSchools.hero.title")}</h2>
                            <p className="w_color f_400 mb_50 wow fadeInRight" data-wow-delay="0.4s">{t("forSchools.hero.description")}</p>
                            <a href="/#" className="btn_hover btn_four wow fadeInRight" data-wow-delay="0.6s">{t("forSchools.hero.button")}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ForSchoolsText;