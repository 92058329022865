import React, { Component } from 'react';
import ForTeachersItem from './ForTeachersItem';
import { withTranslation } from 'react-i18next';

class ForTeachers extends Component{
    render(){
        const { t } = this.props;

        return(
            <section className="software_featured_area" id="for_teachers">
                <div className="container">
                    <h2 className="f_600 f_size_30 t_color3 text-center l_height40 mb_70 wow fadeInUp" data-wow-delay="0.3s">{t("forTeachers.subtitlePart1")}<br />{t("forTeachers.subtitlePart2")}</h2>
                    <div className="row software_featured_info">
                        <ForTeachersItem icon="icon1.png" sTitle={t("forTeachers.autoGradeAssignments")} Sp={t("forTeachers.autoGradeAssignmentsDescription")}
                        /* sbtn="Learn More" */ />
                        <ForTeachersItem icon="icon2.png" sTitle={t("forTeachers.startTeachingInstantly")} Sp={t("forTeachers.startTeachingInstantlyDescription")}
                        /* sbtn="Learn More" */ />
                        <ForTeachersItem icon="icon3.png" sTitle={t("forTeachers.shareAssignments")} Sp={t("forTeachers.shareAssignmentsDescription")}
                        /* sbtn="Learn More" */ />
                    </div>
                </div>
            </section>
        )
    }
}
export default withTranslation()(ForTeachers);