import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import ScrollToHashElement from '../ScrollToHashElement';
import { useTranslation } from 'react-i18next';
import pinecone from '../img/abies_PINECONE_full_angled-300px-01.png';
import { Fade } from 'react-awesome-reveal';
import './About.css';

const About = () => {
  const { t } = useTranslation();

  return (
    <div>
      <ScrollToHashElement />
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        hbtnClass="btn_get_radious menu_custfive"
      />

      <div className="flexContainer">
        <div className="aboutUsText">
          <Fade direction="up">
            <h2>{t('about.aboutUs')}</h2>
          </Fade>
          <p>{t('about.foundationalStory')}</p>
          <p>{t('about.companyOriginStory')}</p>
          <p>{t('about.trainingDetails')}</p>
          <p>{t('about.automationToolsDevelopment')}</p>
        </div>
        <div className="imageAndDictionaryWrapper">
          <div className="imageAndDictionary">
            <img src={pinecone} alt="pinecone" className="imageWidth" />
            <div className="dictionaryDescription">
              <p>{t('common.abiesCapitalized')}</p>
              <div>
                <p>{t('common.abiesPronounce')}</p>
                <p>{t('common.noun')}</p>
              </div>
              <p>{t('about.abiesCapitalizedDefinition')}</p>
              <p>{t('about.abiesDefinition')}</p>
              <div>
                <p>{t('common.etymology')}:</p>
                <p>{t('about.abiesEtymologyRoots')}</p>
                <p>{t('about.abiesLatinOrigin')}</p>
                <p>{t('about.abiesGreekOrigin')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export { About };
