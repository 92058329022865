import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import { Breadcrumb } from '../components/Breadcrumb';
import SignUpC from '../components/SignUp';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import ScrollToHashElement from '../ScrollToHashElement';
import { useTranslation } from 'react-i18next';

const SignUp = () => {
  const { t } = useTranslation();

  return (
    <div className="body_wrapper">
      <ScrollToHashElement />
      <CustomNavbar
        slogo="sticky_logo"
        mClass="menu_four"
        nClass="w_menu"
        hbtnClass="btn_get_radious menu_custfive"
      />
      <Breadcrumb
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle={t('signUp.heroTitle')}
        Pdescription={t('signUp.heroDescription')}
      />
      <SignUpC />
      <FooterTwo FooterData={FooterData} />
    </div>
  );
};
export default SignUp;
