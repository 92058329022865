import React from "react";
import ReactPlayer from "react-player";

const VideoDemo = ({url}) => {
  return (
    <div className="body_wrapper">
      <ReactPlayer
          url={url}
          width="100vw"
          height="100vh"
          controls={true}
        />
    </div>
  )
};
export default VideoDemo;
