import React, {Component} from 'react';
import Player from "./videobtn";
import { withTranslation } from 'react-i18next';

class CloudBanner extends Component{
    state = {
        isIntroOpen: false,
        isPortalOpen: false
    };
    
    onIsIntroOpenModal = () => {
        this.setState(prevState => ({
            isIntroOpen: !prevState.isIntroOpen
        }));
    };

    onIsPortalOpenModal = () => {
        this.setState(prevState => ({
            isPortalOpen: !prevState.isPortalOpen
        }));
    };

    
    render(){
        const { t } = this.props;

        return(
            <section className="software_banner_area d-flex align-items-center" id="home">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 d-flex align-items-center">
                            <div className="software_banner_content">
                                <h2 className="f_500 f_size_50 w_color wow fadeInLeft" data-wow-delay="0.2s">{t("home.teach")} <span className="f_700">{t("home.programming")}</span> {t("home.by")} <span className="f_700">{t("home.practice")}</span></h2>
                                <p className="w_color f_size_18 l_height30 mt_30 wow fadeInLeft" data-wow-delay="0.4s">{t("home.description")}</p>
                                <div className="action_btn d-flex align-items-center mt_40 wow fadeInLeft" data-wow-delay="0.6s">
                                    <a href="/Abies.io_Intro.pdf" className="software_banner_btn" style={{whiteSpace:"nowrap"}}>{t("home.howItWorks")}</a>
                                    <div className="video_btn popup-youtube" onClick={this.onIsIntroOpenModal} style={{whiteSpace:"nowrap"}}>
                                        <div className="icon"><i className="ti-control-play"></i></div><span>{t("home.abiesIntro")}</span>
                                    </div>
                                    <Player videoUrl={'https://vimeo.com/838865302'} open={this.state.isIntroOpen} toggleModal={this.onIsIntroOpenModal} />
                                    <div className="video_btn popup-youtube" onClick={this.onIsPortalOpenModal} style={{whiteSpace:"nowrap"}}>
                                        <div className="icon"><i className="ti-control-play"></i></div><span>{t("home.taskPortal")}</span>
                                    </div>
                                    <Player videoUrl={'https://www.youtube.com/watch?v=AZIUbcN-l_4'} open={this.state.isPortalOpen} toggleModal={this.onIsPortalOpenModal} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="software_img wow fadeInRight" data-wow-delay="0.2s">
                                <img src={require("../../img/home5/banner_img.png")} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withTranslation()(CloudBanner);