// Adapted from https://github.com/ncoughlin/scroll-to-hash-element

import { useMemo, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ScrollToHashElement = () => {
  let location = useLocation();
  let [dirty, setDirty] = useState(false);

  let hashElement = useMemo(() => {
    setDirty(false)
    let hash = location.hash;
    console.log(hash);
    const removeHashCharacter = (str) => {
      const result = str.slice(1);
      return result;
    };

    if (hash) {
      let element = document.getElementById(removeHashCharacter(hash));
      return element;
    } else {
      return null;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, dirty]);

  useEffect(() => {
    if (hashElement) {
      hashElement.scrollIntoView({
        behavior: "smooth",
        // block: "end",
        inline: "nearest",
      });
    } else {
        if(location.hash) {
            setTimeout(() => {setDirty(true)}, 250)
        } else {
            window.scrollTo(0, 0)
        }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashElement]);

  return null;
};

export default ScrollToHashElement;
