import {
    useLocation,
    useResolvedPath,
    UNSAFE_DataRouterStateContext as DataRouterStateContext,
    UNSAFE_NavigationContext as NavigationContext
} from "react-router";
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import useScrollPosition from './useScrollPosition';


// Thanks to here: https://stackoverflow.com/questions/1480133/how-can-i-get-an-objects-absolute-position-on-the-page-in-javascript
var cumulativeOffset = function (element) {
    var top = 0, left = 0;
    do {
        top += element.offsetTop || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while (element);

    return {
        top: top,
        left: left
    };
};


// Refactored from react-router's source code.
export const HashNavLink = React.forwardRef(
    function NavLinkWithRef(
        {
            "aria-current": ariaCurrentProp = "page",
            caseSensitive = false,
            className: classNameProp = "",
            end = false,
            style: styleProp,
            "target-id": targetId,
            to,
            children,
            ...rest
        },
        ref
    ) {
        let path = useResolvedPath(to, { relative: rest.relative });
        let location = useLocation();
        let routerState = React.useContext(DataRouterStateContext);
        let { navigator } = React.useContext(NavigationContext);

        let toPathname = navigator.encodeLocation
            ? navigator.encodeLocation(path).pathname
            : path.pathname;
        let locationPathname = location.pathname;
        let nextLocationPathname =
            routerState && routerState.navigation && routerState.navigation.location
                ? routerState.navigation.location.pathname
                : null;

        if (!caseSensitive) {
            locationPathname = locationPathname.toLowerCase();
            nextLocationPathname = nextLocationPathname
                ? nextLocationPathname.toLowerCase()
                : null;
            toPathname = toPathname.toLowerCase();
        }

        let [isActive, setIsActive] = useState(false);
        

        let isPending =
            nextLocationPathname != null &&
            (nextLocationPathname === toPathname ||
                (!end &&
                    nextLocationPathname.startsWith(toPathname) &&
                    nextLocationPathname.charAt(toPathname.length) === "/"));

        let ariaCurrent = isActive ? ariaCurrentProp : undefined;

        const scrollPosition = useScrollPosition();

        useEffect(() => {
            let tempIsActive = locationPathname === toPathname ||
            (!end &&
                locationPathname.startsWith(toPathname) &&
                locationPathname.charAt(toPathname.length) === "/")
            if (targetId) {
                const targetElem = document.getElementById(targetId);
                if (targetElem && tempIsActive) {
                    let targetPos = cumulativeOffset(document.getElementById(targetId));
                    let targetStart = targetPos.top;
                    let targetEnd = targetStart + targetElem.offsetHeight;
                    // console.log(targetElem);
                    // console.log(targetId)
    
                    // console.log('Current offset: ', scrollPosition);
                    // console.log('Current end of offset', scrollPosition + window.innerHeight)
                    // console.log('Target offset: ', targetStart, targetEnd);
                    // const flatOffset = 200;
                    // if(scrollPosition + flatOffset < targetStart || scrollPosition + window.innerHeight + flatOffset >= targetEnd) {
                    //     tempIsActive = false;
                    // }
                    const visStart = Math.max(scrollPosition, targetStart)
                    const visEnd = Math.min(scrollPosition + window.innerHeight, targetEnd);
                    if(visEnd - visStart < 0.6 * Math.min(window.innerHeight, targetElem.offsetHeight)) {
                        tempIsActive = false;
                    }
                    // cons
                } else {
                    tempIsActive = false;
                }
            }
            setIsActive(tempIsActive)
        }, [locationPathname, targetId, end, scrollPosition, toPathname]);

        let className;
        if (typeof classNameProp === "function") {
            className = classNameProp({ isActive, isPending });
        } else {
            // If the className prop is not a function, we use a default `active`
            // class for <NavLink />s that are active. In v5 `active` was the default
            // value for `activeClassName`, but we are removing that API and can still
            // use the old default behavior for a cleaner upgrade path and keep the
            // simple styling rules working as they currently do.
            className = [
                classNameProp,
                isActive ? "active" : null,
                isPending ? "pending" : null,
            ]
                .filter(Boolean)
                .join(" ");
        }

        let defaultStyle = { whiteSpace: "nowrap" };
        let style =
            typeof styleProp === "function"
                ? styleProp({ isActive, isPending })
                : { ...defaultStyle, ...styleProp };

        return (
            <Link
                {...rest}
                aria-current={ariaCurrent}
                className={className}
                ref={ref}
                style={style}
                to={to}
            >
                {typeof children === "function"
                    ? children({ isActive, isPending })
                    : children}
            </Link>
        );
    }
);