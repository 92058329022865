import React from 'react';
import Sectitle from "../Title/Sectitle";
import Tabitem from "./ForSchoolsTabItem";
// import { faArrowsSpin } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';

const ForSchoolsTabs =()=>{
    const { t } = useTranslation();
    return(
        <section className="software_service_area sec_pad" id="for_schools">
            <div className="container">
                <Sectitle Title={t("forSchools.subtitle")} TitleP=""
                 sClass="sec_title text-center mb_70"/>
                <div className="row">
                    <div className="col-lg-3 col-md-3">
                        <ul className="nav nav-tabs software_service_tab" id="myTab" role="tablist">
                            <li className="nav-item">
                                <button className="nav-link active" id="de-tab" data-bs-toggle="tab" role="tab" href="#de" aria-controls="de" aria-selected="true">{t("forSchools.alwaysOn.title")}</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" id="saas-tab" data-bs-toggle="tab" role="tab" href="#saas" aria-controls="saas" aria-selected="false">{t("forSchools.pricing.title")}</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" id="ma-tab" data-bs-toggle="tab" role="tab" href="#ma" aria-controls="ma" aria-selected="true">{t("forSchools.fair.title")}</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" id="secure-tab" data-bs-toggle="tab" role="tab" href="#secure" aria-controls="secure" aria-selected="false">{t("forSchools.extensive.title")}</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" id="monitor-tab" data-bs-toggle="tab" role="tab" href="#monitor" aria-controls="monitor" aria-selected="false">{t("forSchools.batteriesIncluded.title")}</button>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-9 col-md-9">
                        <div className="tab-content software_service_tab_content">
                            <div className="tab-pane fade show active" id="de" role="tabpanel" aria-labelledby="de-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-automatic" text={t("forSchools.alwaysOn.automatic.title")} description={t("forSchools.alwaysOn.automatic.description")}/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-globe" text={t("forSchools.alwaysOn.available.title")} description={t("forSchools.alwaysOn.available.description")}/>
                                    <Tabitem ticon="ti-face-smile" text={t("forSchools.alwaysOn.support.title")} description={t("forSchools.alwaysOn.support.description")}/>
                                    {/* <Tabitem colClass="offset-lg-2" ticon="ti-harddrives" text="Backups & Snapshots" description="Crikey blow off chap knackered gosh bobby geeza gormless well, Charles cheeky bugger I cor blimey guvnor hanky panky jolly good."/> */}
                                </div>
                            </div>
                            <div className="tab-pane fade" id="saas" role="tabpanel" aria-labelledby="saas-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-face-smile" text={t("forSchools.pricing.affordable.title")} description={t("forSchools.pricing.affordable.description")}/>
                                    {/* <Tabitem colClass="offset-lg-2" ticon="ti-harddrives" text="Pricing" htmlDescription="Pricing starts at 5 EUR / student / month, and you can get a discount for a larger number of students.<br />Additionally, you can join our Private Beta completely free of charge and even win credits for the Public release as an appreciation of your valuable feedback."/> */}
                                    <Tabitem colClass="offset-lg-2" ticon="ti-harddrives" text={t("forSchools.pricing.pricing.title")} description-html={`${t("forSchools.pricing.pricing.descriptionPart1")} </br> ${t("forSchools.pricing.pricing.descriptionPart2")}`}/>
                                    {/* <Tabitem colClass="offset-lg-2" ticon="ti-basketball" text="Global Availability" description="Crikey blow off chap knackered gosh bobby geeza gormless well, Charles cheeky bugger I cor blimey guvnor hanky panky jolly good."/> */}
                                </div>
                            </div>
                            <div className="tab-pane fade" id="ma" role="tabpanel" aria-labelledby="ma-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-user" text={t("forSchools.fair.consistent.title")} description={t("forSchools.fair.consistent.description")}/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-basketball" text={t("forSchools.fair.hollistic.title")} description={t("forSchools.fair.hollistic.description")}/>
                                    <Tabitem ticon="ti-face-smile" text={t("forSchools.fair.feedback.title")} description={t("forSchools.fair.feedback.description")}/>
                                    {/* <Tabitem colClass="offset-lg-2" ticon="ti-harddrives" text="Backups & Snapshots" description="Crikey blow off chap knackered gosh bobby geeza gormless well, Charles cheeky bugger I cor blimey guvnor hanky panky jolly good."/> */}
                                </div>
                            </div>
                            <div className="tab-pane fade" id="secure" role="tabpanel" aria-labelledby="secure-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-user" text={t("forSchools.extensive.variety.title")} description={t("forSchools.extensive.variety.description")}/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-basketball" text={t("forSchools.extensive.tiered.title")} description-html={`${t("forSchools.extensive.tiered.description")} <ul>
                                    <li>${t("forSchools.extensive.tiered.bulletA")}</li>
                                    <li>${t("forSchools.extensive.tiered.bulletB")}</li>
                                    <li>${t("forSchools.extensive.tiered.bulletC")}</li>
                                    </ul>`}/>
                                    <Tabitem ticon="ti-face-smile" text={t("forSchools.extensive.collaborative.title")} description={t("forSchools.extensive.collaborative.description")}/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-harddrives" text={t("forSchools.extensive.integration.title")} description={t("forSchools.extensive.integration.description")}/>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="monitor" role="tabpanel" aria-labelledby="monitor-tab">
                                <div className="row">
                                    <Tabitem ticon="ti-user" text={t("forSchools.batteriesIncluded.integratesWithLMS.title")} description={t("forSchools.batteriesIncluded.integratesWithLMS.description")}/>
                                    <Tabitem colClass="offset-lg-2" ticon="ti-basketball" text={t("forSchools.batteriesIncluded.supportsMultipleLanguages.title")} description={t("forSchools.batteriesIncluded.supportsMultipleLanguages.description")}/>
                                    <Tabitem ticon="ti-face-smile" text={t("forSchools.batteriesIncluded.flexibleAuthentication.title")} description={t("forSchools.batteriesIncluded.flexibleAuthentication.description")}/>
                                    {/* <Tabitem colClass="offset-lg-2" ticon="ti-harddrives" text="Backups & Snapshots" description="Crikey blow off chap knackered gosh bobby geeza gormless well, Charles cheeky bugger I cor blimey guvnor hanky panky jolly good."/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ForSchoolsTabs;