import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';

class ForStudentsInfo extends Component {
    render(){
        const { t } = this.props;

        return(
            <section className="developer_product_area" id="for_students">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <div className="developer_product_content">
                                <h2 className="f_600 f_size_30 l_height30 t_color3 mb_50 wow fadeInUp" data-wow-delay="0.2s">{t('forStudents.subtitle')}</h2>
                                <p className="f_size_18 l_height30 mt_30 wow fadeInLeft" data-wow-delay="0.4s">{t('forStudents.description')}</p>
                                <br/>
                                <ul className="nav nav-tabs develor_tab mb-30" id="myTab2" role="tablist" style={{display: "flex", flexWrap: "nowrap"}}>
                                    <li className="nav-item">
                                        <button className="nav-link active" data-tab="tab_one" id="ruby-tab" data-bs-toggle="tab" href="#ruby" role="tab" aria-controls="ruby" aria-selected="true">{t('forStudents.instant.title')}</button>
                                    </li>
                                    <li className="nav-item">
                                        <button className="nav-link" data-tab="tab_two" id="curl-tab" data-bs-toggle="tab" href="#curl" role="tab" aria-controls="curl" aria-selected="false">{t('forStudents.fair.title')}</button>
                                    </li>
                                    <li className="nav-item" style={{whiteSpace: "nowrap"}}>
                                        <button className="nav-link" data-tab="tab_three" id="resize-tab" data-bs-toggle="tab" href="#resize" role="tab" aria-controls="resize" aria-selected="false">{t('forStudents.tailored.title')}</button>
                                    </li>
                                    <li className="nav-item" style={{whiteSpace: "nowrap"}}>
                                        <button className="nav-link" data-tab="tab_four" id="doctl-tab" data-bs-toggle="tab" href="#doctl" role="tab" aria-controls="doctl" aria-selected="false">{t('forStudents.noHassle.title')}</button>
                                    </li>
                                </ul>
                                <div className="tab-content developer_tab_content">
                                    <div className="tab-pane fade show active" id="ruby" role="tabpanel" aria-labelledby="ruby-tab">
                                        <p className="mb_40">{t('forStudents.instant.description')}</p>
                                        <a href="/#" className="details_btn">{t('common.moreInfo')}</a>
                                    </div>
                                    <div className="tab-pane fade" id="curl" role="tabpanel" aria-labelledby="curl-tab">
                                        <p className="mb_40">{t('forStudents.fair.description')}</p>
                                        <a href="/#" className="details_btn">{t('common.moreInfo')}</a>
                                    </div>
                                    <div className="tab-pane fade" id="resize" role="tabpanel" aria-labelledby="resize-tab">
                                        <p className="mb_40">{t('forStudents.tailored.descriptionPart1')}<br />
                                        {t('forStudents.tailored.descriptionPart2')}</p>
                                        <a href="/#" className="details_btn">{t('common.moreInfo')}</a>
                                    </div>
                                    <div className="tab-pane fade" id="doctl" role="tabpanel" aria-labelledby="doctl-tab">
                                        <p className="mb_40">{t('forStudents.noHassle.description')}</p>
	                                    <a href="/#" className="details_btn">{t('common.moreInfo')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="tab_img_info">
                                <div className="tab_img active" id="tab_one">
                                    <img className="img-fluid wow fadeInRight" data-wow-delay="0.4s" src={require ("../img/home5/code.jpg")} alt=""/>
                                </div>
                                <div className="tab_img" id="tab_two">
                                    <img className="img-fluid wow fadeInRight" data-wow-delay="0.4s" src={require ("../img/home5/01.jpg")} alt=""/>
                                </div>
                                <div className="tab_img" id="tab_three">
                                    <img className="img-fluid wow fadeInRight" data-wow-delay="0.4s" src={require ("../img/home5/02.jpg")} alt=""/>
                                </div>
                                <div className="tab_img" id="tab_four">
                                    <img className="img-fluid wow fadeInRight" data-wow-delay="0.4s" src={require ("../img/home5/03.jpg")} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withTranslation()(ForStudentsInfo);