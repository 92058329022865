import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class ServiceSubscribe extends Component {
    render() {
        let { sClass } = this.props;
        const { t } = this.props;
        
        return (
            <div className="container">
                <div className={`subscribe_form_info text-center ${sClass}`}>
                    <h2 className="f_600 f_size_30 l_height30 t_color3 mb_50">{t("common.subscribeForNewsletter")}</h2>
                    <form className="subscribe-form" action="https://abies.us21.list-manage.com/subscribe/post" method="POST">
                        <input type="hidden" name="u" value="c38e3e19a9ada2548c25b879f" />
                        <input type="hidden" name="id" value="e60a074341" />
                        <input type="email" className="form-control" placeholder={t("common.yourEmail")} autoCapitalize="off" autoCorrect="off" name="MERGE0" id="MERGE0" size="25" defaultValue="" />
                        <button type="submit" className="btn_hover btn_four mt_40">{t("common.subscribe")}</button>
                    </form>
                </div>
            </div>
        )
    }
}
export default withTranslation()(ServiceSubscribe);