import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { translationEN } from "./en";
import { translationBG } from "./bg";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: "bg",
    fallbackLng: "bg",
    resources: {
      bg: {
        translation: translationBG,
      },
      en: {
        translation: translationEN,
      },
    },
  });

export default i18n;
