import React, { useEffect } from "react";
import {createBrowserRouter, RouterProvider } from "react-router-dom";
/*------ Pages-----*/
// import { Home } from "./Pages/Home";
import Contact from "./Pages/Contact";
import HomeCloud from "./Pages/Home-cloud";
import NotFound from "./Pages/404";
import VideoDemo from "./Pages/VideoDemo";
import SignUp from "./Pages/SignUp";
import { About } from "./Pages/About";
import { LangRouteWrapper } from "./i18n/LangRouteWrapper";

const App = (props) => {
  const router = createBrowserRouter([{
    path: '/:lang?',
    element: <LangRouteWrapper/>,
    errorElement: <NotFound />,
    children: [
      {
        children: [
          {
            path: '', element: <HomeCloud />
          }, {
            path: 'Contact', element: <Contact />
          },
          {
            path: 'About', element: <About />
          }, {
            path: 'Demo', element: <VideoDemo url="https://vimeo.com/838865302" />
          }, {
            path: 'Portal', element: <VideoDemo url="https://www.youtube.com/watch?v=AZIUbcN-l_4" />
          }, {
            path: 'signup', element: <SignUp />
          }
        ]
      }
    ]
  }]);

  useEffect(() => {
    props.hideLoader();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RouterProvider router={router} />
  );
}

export default App;
