import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import CloudBanner from '../components/Banner/CloudBanner';
import ForTeachers from '../components/Features/ForTeachers';
import ForSchoolsTabs from '../components/Features/ForSchoolsTabs';
import TeachingBasicFeatures from '../components/Features/TeachingBasicFeatures';
import ForSchoolsText from '../components/Features/ForSchoolsText';
import ForStudentsInfo from '../components/DeveloperTab';
// import Partner from '../components/Partner';
import ServiceSubscribe from '../components/ServiceSubscribe';
import FooterTwo from '../components/Footer/FooterTwo';
import FooterData from '../components/Footer/FooterData';
import ScrollToHashElement from "../ScrollToHashElement";

const HomeCloud = () => {
    return(
        <div className="body_wrapper">
            <ScrollToHashElement />
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu" hbtnClass="btn_get_radious menu_custfive"/>
            <CloudBanner/>
            <ForTeachers/>
            <TeachingBasicFeatures/>
            <ForSchoolsTabs/>
            <ForSchoolsText/>
            <ForStudentsInfo/>
            {/* <Partner pClass="partner_logo_area_two" pClasst="pt-0 mb-0"/> */}
            <ServiceSubscribe/>
            <FooterTwo fClass="pt_150" FooterData={FooterData}/>
        </div>
    )
}
export default HomeCloud;