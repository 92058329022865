import React, {Component} from 'react';
import { Fade } from 'react-awesome-reveal';
class Sectitle  extends Component {
    render(){
        var {Title, TitleP, tClass,sClass} = this.props;
        return(
            <div className={`${sClass}`}>
                <Fade direction="up" duration={1300}><h2 className={`f_p f_size_30 l_height50 f_600 ${tClass}`}>{Title}</h2></Fade>
                <Fade direction="up" duration={1600}><p className="f_400 f_size_16 mb-0">{TitleP}</p></Fade>
            </div>
        )
    }
}
export default Sectitle;
